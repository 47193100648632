import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {AbstractHttpService} from './abstract-http-service';
import {CruiseControlSettingsInterface} from '../model/cruise-control-settings.interface';
import {CruiseControlSelectionCriteriaInterface} from '../model/cruise-control-selection-criteria.interface';
import {CruiseControlStatusInterface} from '../model/cruise-control-status.interface';

@Injectable({
  providedIn: 'root'
})
export class CruiseControlService extends AbstractHttpService {

  defaultOptions = {
    headers: this.contentTypeJsonHeaders,
    withCredentials: true
  };

  constructor(private httpClient: HttpClient) {
    super();
  }


  /**
   * Gets Cruise Control settings for an account and vehicle condition
   * @param accountId
   * @param condition
   */
  getSettings(accountId: number, condition: string): Observable<CruiseControlSettingsInterface> {
    return this.httpClient.get<CruiseControlSettingsInterface>(
      `//${environment.autoDomain}:${environment.autoPort}/api/cruise-control-settings?accountId=${accountId}&condition=${condition}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Gets list of available vehicle selection criteria
   */
  getSelectionCriteria(): Observable<CruiseControlSelectionCriteriaInterface[]> {
    return this.httpClient.get<CruiseControlSelectionCriteriaInterface[]>(
      `//${environment.autoDomain}:${environment.autoPort}/api/cruise-control/selection-criteria`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Gets the status of the most recent Cruise Control command by account and vehicle condition
   * @param accountId
   * @param condition
   */
  getStatus(accountId: number, condition: string): Observable<CruiseControlStatusInterface> {
    return this.httpClient.get<CruiseControlStatusInterface>(
      `//${environment.autoDomain}:${environment.autoPort}/api/cruise-control/status?accountId=${accountId}&condition=${condition}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Updates Cruise Control settings
   * @param settings
   */
  updateSettings(settings: CruiseControlSettingsInterface): Observable<Object> {
    return this.httpClient.put(
      `//${environment.autoDomain}:${environment.autoPort}/api/cruise-control-settings`,
      settings,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Starts a new Cruise Control command
   * @param accountId
   * @param condition
   */
  run(accountId: number, condition: string): Observable<Object> {
    return this.httpClient.post(
      `//${environment.launchcontrolAdminDomain}:${environment.autoPort}/includes/ajax/api/automation/cruise-control`,
      {accountId, condition},
      this.defaultOptions
    ).pipe(
      catchError(this.handleErrorWithResponse)
    );
  }

  /**
   * Starts a new Refresh Rebates command
   * @param accountId
   */
  refreshRebates(accountId: number): Observable<any> {
    return this.httpClient.get(
      `//${environment.launchcontrolAdminDomain}:${environment.autoPort}/includes/ajax/mpop-api/queue-refresh-rebate-command/${accountId}`,
      this.defaultOptions
    ).pipe(
      catchError(this.handleError)
    );
  }
}
