import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../service/navigation.service';
import {MenuItem, MessageService} from 'primeng/api';
import {CruiseControlService} from '../../../../service/cruise-control.service';
import {CruiseControlSettingsInterface} from '../../../../model/cruise-control-settings.interface';
import {CruiseControlStatusInterface} from '../../../../model/cruise-control-status.interface';

@Component({
  selector: 'auto-cruise-control',
  templateUrl: './cruise-control.component.html',
  styleUrl: './cruise-control.component.scss'
})
export class CruiseControlComponent {
  accountId: number;
  settingsSideNav: MenuItem[] = [];
  newSettings: CruiseControlSettingsInterface;
  usedSettings: CruiseControlSettingsInterface;
  newStatus: CruiseControlStatusInterface;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private cruiseControlService: CruiseControlService,
    private messageService: MessageService
  ) {
  }

  /**
   * Initialize component
   */
  ngOnInit(): void {
    this.titleService.setTitle('Cruise Control');
    this.route.paramMap.subscribe((params) => {
      this.accountId = parseInt(params.get('accountId'));
      this.initSettings();
    });
    this.initNavigation();
  }

  /**
   * Initializes side-nav options
   */
  initNavigation(): void {
    this.navigationService.getMenuItems(this.accountId, 'settings-view-side-menu.configuration.json')
      .then((navItems) => {
        this.settingsSideNav = navItems;
      });
  }

  /**
   * Initializes Cruise Control settings for new and used ads
   */
  initSettings(): void {
    this.cruiseControlService.getSettings(this.accountId, 'N').subscribe({
      next: (settings) => this.newSettings = settings,
      error: () => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Unable to retrieve settings for new vehicles'})
    });

    this.cruiseControlService.getSettings(this.accountId, 'U').subscribe({
      next: (settings) => this.usedSettings = settings,
      error: () => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Unable to retrieve settings for used vehicles'})
    });
  }
}
