<auto-main-navigation [accountId]="accountId"></auto-main-navigation>

<p-panel>
  <p-header class="settings-header-wrapper">
    <h1 class="settings-header">MPOP Settings</h1>
  </p-header>

  <div class="main-content-wrapper p-grid">

    <div class="p-col-3">
      <p-panelMenu [model]="settingsSideNav">
      </p-panelMenu>
    </div>

    <p-panel class="p-col ad-types-wrapper">

      <p-header class="ad-types-header-wrapper">
        <h2 class="setting-name">Cruise Control</h2>
      </p-header>


      <p-tabView>

        <p-confirmDialog #cd>
          <ng-template pTemplate="headless" let-message>
            <div class="surface-overlay">
              <div class="popup-icon">
                <i [class]="message.icon"></i>
              </div>
              <span class="popup-title">
                {{ message.header }}
              </span>


              <p class="popup-message">You are about to run Cruise Control automation to build ads for <span class="popup-vehicle-condition p-badge-warning">{{message.message}}</span> vehicles. <br/><br/> Do you wish to proceed?</p>
              <div class="popup-buttons">
                <button
                  pButton
                  label="Cancel"
                  (click)="cd.reject()"
                  class="p-button-outlined w-8rem ">
                </button>
                <button
                  pButton
                  label="Run"
                  (click)="cd.accept()"
                  class="w-8rem">
                </button>
              </div>
            </div>
          </ng-template>
        </p-confirmDialog>

        <p-tabPanel header="New">
          <auto-cruise-control-form *ngIf="newSettings" [accountId]="accountId" [settings]="newSettings" label="New" />
        </p-tabPanel>
        <p-tabPanel header="Used">
          <auto-cruise-control-form *ngIf="usedSettings" [accountId]="accountId" [settings]="usedSettings" label="Used" />
        </p-tabPanel>
      </p-tabView>

    </p-panel>
  </div>


</p-panel>


<auto-footer></auto-footer>
