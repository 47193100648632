<div class="two-panel-layout">

  <div class="settings">

    <p-header class="setting-content">
      <span class="setting-name">Cruise Control for {{label}} Ads</span>
      <div class="ad-types-header-input">
        <div class="ad-types-header-input">
          <p-inputSwitch *ngIf="settings" [(ngModel)]="settings.enabled" (ngModelChange)="updateSettings()" />
        </div>
      </div>
    </p-header>

    <p-divider />

    <p-header class="setting-content">
      <span class="setting-name">Generate As</span>
      <div class="ad-types-header-input">
        <div class="ad-types-header-input">
          <p-selectButton
            *ngIf="settings"
            [options]="generateAsOptions"
            [(ngModel)]="settings.generateAsPreview"
            (ngModelChange)="updateSettings()"

            optionLabel="label"
            optionValue="value" />
        </div>
      </div>
    </p-header>

    <p-divider />

    <p-header class="setting-content">
      <span class="setting-name">Build one ad per</span>
      <div class="ad-types-header-input">
        <div class="ad-types-header-input">
          <p-selectButton
            *ngIf="settings"
            [options]="buildByOptions"
            [(ngModel)]="settings.cruiseControlCreateLevelId"
            (ngModelChange)="updateSettings()"

            optionLabel="label"
            optionValue="value" />
        </div>
      </div>
    </p-header>

    <p-divider />

    <p-header class="setting-content">
      <span class="setting-name">Vehicle Selection Criteria</span>
      <div class="ad-types-header-input">
        <div class="ad-types-header-input">
          <p-dropdown
            *ngIf="settings && selectionCriteria.length > 0"
            [options]="selectionCriteria"
            [(ngModel)]="settings.vehicleSelection"
            [disabled]="settings.cruiseControlCreateLevelId === 3"
            (ngModelChange)="updateSettings()"
            optionLabel="criteria"
            optionValue="id">
          </p-dropdown>
        </div>
      </div>
    </p-header>

    <p-divider />

    <p-header class="setting-content">
      <span class="setting-name">Minimum Image Requirement</span>
      <div class="ad-types-header-input">
        <div class="ad-types-header-input">
          <p-dropdown
            *ngIf="settings"
            [options]="minimumImageOptions"
            [(ngModel)]="settings.minimumImageRequirement"
            (ngModelChange)="updateSettings()"
            optionLabel="label"
            optionValue="value">
          </p-dropdown>
        </div>
      </div>
    </p-header>

    <p-divider *ngIf="!settings.isVehicleAdsEnabled" />

    <p-header class="setting-content" *ngIf="settings && !settings.isVehicleAdsEnabled">
      <span class="setting-name">Pricing Options</span>
      <div class="pricing-options">

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.financePayment"
            [binary]="true"
            [value]="false"
            (ngModelChange)="updateSettings()"
            inputId="ny" />
          <label for="ny">Finance Payment</label>

        </div>

        <div class="pricing-options-input sub">
          <p-radioButton
            *ngIf="settings"
            [(ngModel)]="settings.useGlobalFinanceApr"
            [value]="true"
            (ngModelChange)="updateSettings()"
            inputId="globalFinance" />
          <label for="globalFinance">MPOP Global Finance Payment APR</label>
        </div>
        <div class="pricing-options-input sub">
          <p-radioButton
            *ngIf="settings"
            [(ngModel)]="settings.useGlobalFinanceApr"
            [value]="false"
            (ngModelChange)="updateSettings()"
            inputId="captiveFinance" />
          <label for="captiveFinance">Captive Lender Rate</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.apr"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="apr" />
          <label for="apr">APR</label>

        </div>
        <div class="pricing-options-input sub">
          <p-radioButton
            *ngIf="settings"
            [(ngModel)]="settings.useGlobalApr"
            [value]="true"
            (ngModelChange)="updateSettings()"
            inputId="globalApr" />
          <label for="globalApr">MPOP Global APR</label>
        </div>
        <div class="pricing-options-input sub">
          <p-radioButton
            *ngIf="settings"
            [(ngModel)]="settings.useGlobalApr"
            [value]="false"
            (ngModelChange)="updateSettings()"
            inputId="captiveApr" />
          <label for="captiveApr">Captive Lender Rate</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.salePrice"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="salePrice" />
          <label for="salePrice">Sale Price</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.savings"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="savings" />
          <label for="savings">Savings</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.leasePricing"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="leasePricing" />
          <label for="leasePricing">Lease Pricing</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.zeroDownLeasePricing"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="zeroDown" />
          <label for="zeroDown">Zero Down Lease Pricing</label>
        </div>

        <div class="pricing-options-input">
          <p-checkbox
            *ngIf="settings"
            [(ngModel)]="settings.rebates"
            [binary]="true"
            (ngModelChange)="updateSettings()"
            inputId="rebates" />
          <label for="rebates">Rebates and Incentives</label>
        </div>
      </div>
    </p-header>
  </div>

  <p-card class="controls">
    <p-button *ngIf="status && (status.completed || !status.created)" [disabled]="settings && !settings.enabled" label="Run Cruise Control" (click)="confirmRun($event)"/>
    <p-button *ngIf="status && status.created && !status.completed" label="Run Cruise Control" icon="pi pi-spin pi-spinner" [disabled]="true" />

    <p-fieldset *ngIf="status && status.created" legend="Status">
      <p-steps [model]="progressSteps" [activeIndex]="progressIndex" />
      <p-messages *ngIf="commandMessages.length > 0" [value]="commandMessages" [enableService]="false" [closable]="false" />
      <p class="created-date">{{status.created | date: 'short' : '-100' }}</p>
    </p-fieldset>

    <p-divider *ngIf="settings.vehicleCondition === 'N'" />
    <p-button
      *ngIf="settings && settings.vehicleCondition === 'N' && status && (!status.refreshRebatesCreated || status.refreshRebatesCompleted || settings.isEnhancedIncentivesEnabled)"
      label="Refresh Rebates"
      severity="secondary"
      [disabled]="settings.isEnhancedIncentivesEnabled"
      (click)="refreshRebates()" />
    <p-button
      *ngIf="settings && settings.vehicleCondition === 'N' && status && status.refreshRebatesCreated && !status.refreshRebatesCompleted && !settings.isEnhancedIncentivesEnabled"
      icon="pi pi-spin pi-spinner"
      label="Refresh Rebates"
      severity="secondary"
      [disabled]="true" />
    <p-messages
      class="ei-message"
      *ngIf="settings && settings.isEnhancedIncentivesEnabled && settings.vehicleCondition === 'N'"
      [value]="enhancedIncentivesMessages"
      [closable]="false"
      [enableService]="false"
      />
  </p-card>
</div>
