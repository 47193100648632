import {Component, Input} from '@angular/core';
import {ConfirmationService, MenuItem, Message, MessageService} from 'primeng/api';
import {
  CruiseControlSelectionCriteriaInterface
} from '../../../../../model/cruise-control-selection-criteria.interface';
import {CruiseControlSettingsInterface} from '../../../../../model/cruise-control-settings.interface';
import {CruiseControlStatusInterface} from '../../../../../model/cruise-control-status.interface';
import {CruiseControlService} from '../../../../../service/cruise-control.service';
import {repeat, Subject, takeUntil} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'auto-cruise-control-form',
  templateUrl: './cruise-control-form.component.html',
  styleUrl: './cruise-control-form.component.scss'
})
export class CruiseControlFormComponent {
  @Input() accountId: number;
  @Input() settings: CruiseControlSettingsInterface;
  @Input() label: string;
  generateAsOptions: object[] = [{value: false, label: 'Active'}, {value: true, label: 'Inactive'}]
  buildByOptions: object[] = [{value: 1, label: 'Model'}, {value: 2, label: 'Trim'}, {value: 3, label: 'Vehicle'}]
  selectionCriteria: CruiseControlSelectionCriteriaInterface[] = [];
  minimumImageOptions: object[] = []
  status: CruiseControlStatusInterface;
  enhancedIncentivesMessages: Message[] = [{detail: 'Enhanced Incentives is enabled. Rebates are automatically refreshed.', severity: 'info'}];
  progressSteps: MenuItem[] = [{label: 'Queued'}, {label: 'In Progress'}, {label: 'Done'}];
  progressIndex: number = 0;
  commandMessages: Message[] = [];
  pollingSubject: Subject<any> = new Subject<any>();
  updateCount: number = 0;
  lastCommandCreated: string;
  monitorNewCommand: boolean = false;

  constructor(private cruiseControlService: CruiseControlService, private messageService: MessageService, private confirmationService: ConfirmationService) {
  }

  /**
   * Init component
   */
  ngOnInit(): void {
    this.populateSelectionCriteria();
    this.populateMinimumImageRequirementOptions();
    this.updateStatus();
  }

  /**
   * Populates options for the minimum image requirements form field
   */
  populateMinimumImageRequirementOptions(): void {
    for (let i = 0; i < 21; i++) {
      this.minimumImageOptions.push({ value: i, label: i });
    }
  }

  /**
   * Populates the vehicle selection criteria options for the Vehicle Selection Criteria form field
   */
  populateSelectionCriteria(): void {
    this.cruiseControlService.getSelectionCriteria().subscribe({
      next: (list) => {
        for (const item of list) {
          if (this.settings.vehicleCondition === item.vehicleCondition) {
            this.selectionCriteria.push(item);
          }
        }
      },
      error: () => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Unable to retrieve vehicle selection criteria'})
    });
  }

  /**
   * Updates the Cruise Control command status on page load
   */
  updateStatus(): void {
    this.cruiseControlService.getStatus(this.accountId, this.settings.vehicleCondition).subscribe({
      next: (status) => {
        this.status = status;
        this.updateProgress(status);
        this.lastCommandCreated = status.created;
        if (status.created && !status.completed) {
          this.startStatusPolling();
          this.messageService.add({severity: 'info', detail: `Cruise Control is currently running for ${this.label.toLowerCase()} inventory`});
        }
      },
      error: () => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Unable to retrieve Cruise Control command status'})
    });
  }

  /**
   * Updates the progress index for the most recent Cruise Control command
   * @param status
   */
  updateProgress(status: CruiseControlStatusInterface): void {
    this.commandMessages = [];
    if (status.pending) {
      this.progressIndex = 0;
    }

    if (status.started) {
      this.progressIndex = 1;
    }

    if (status.completed) {
      this.progressIndex = 2;
      const result = JSON.parse(status.result);

      if (result.errors) {
        this.commandMessages.push({severity: 'error', detail: result.errors});
      } else {
        if (status.adsCreated === 0 || !status.adsCreated) {
          this.commandMessages.push({severity: 'success', detail: 'All ads are up to date.'});
        } else {
          this.commandMessages.push({severity: 'success', detail: `${status.adsCreated} ads created.`});
        }
      }
    }
  }

  /**
   * Shows the modal to confirm the user wants to start a new cruise control command
   * @param event
   */
  confirmRun(event: MouseEvent): void {
    this.confirmationService.confirm({
      target: event.target,
      header: 'Warning',
      message: this.label,
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => this.startCruiseControl()
    });
  }

  /**
   * Saves the changed settings values
   */
  updateSettings(): void {
    this.updateCount++;

    if (this.updateCount > 2) {
      this.cruiseControlService.updateSettings(this.settings).subscribe({
        next: () => this.messageService.add({
          severity: 'success', summary: 'Success', detail: 'Settings were updated'}),
        error: () => this.messageService.add({
          severity: 'error', summary: 'Error', detail: 'Failed to save settings'})
      });
    }
  }

  /**
   * Starts a new Cruise Control command
   */
  startCruiseControl(): void {
    this.startStatusPolling();
    this.progressIndex = 0;
    this.commandMessages = [];
    this.messageService.add({
      severity: 'info', summary: 'Info', detail: `Starting Cruise Control for ${this.label.toLowerCase()} ads`});
    this.cruiseControlService.run(this.accountId, this.settings.vehicleCondition).subscribe({
      next: (response: any) => {
        this.cancelStatusPolling();
        this.updateStatus();

        if (response.errors && response.errors.length > 0) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Cruise Control failed to complete for ${this.label.toLowerCase()} ads with the following message: ${response.errors[0]}`});
        } else {
          this.messageService.add({
            severity: 'success', summary: 'Success', detail: `Cruise Control completed for ${this.label.toLowerCase()} ads`});
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status !== 504) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Cruise Control ran into an issue when building ${this.label.toLowerCase()} ads and could not complete. View status for more details.`});
        }
      }
    });
  }

  /**
   * Polls the status of the most recent Cruise Control command
   */
  startStatusPolling(): void {
    this.cruiseControlService.getStatus(this.accountId, this.settings.vehicleCondition)
      .pipe(
        repeat({delay: 1000}),
        takeUntil(this.pollingSubject)
      )
      .subscribe({
        next: (status) => {
          if (this.lastCommandCreated !== status.created) {
            this.lastCommandCreated = status.created;
            this.monitorNewCommand = true;
          }

          if (this.monitorNewCommand) {
            this.status = status;
            this.updateProgress(status);

            if (status.completed) {
              const result = JSON.parse(status.result);
              this.monitorNewCommand = false;
              this.cancelStatusPolling();

              if (result.errors) {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: `Cruise Control failed to complete for ${this.label.toLowerCase()} ads with the following message: ${result.errors}`});
              } else {
                this.messageService.add({severity: 'success', summary: 'Success', detail: 'Cruise Control completed'});
              }
            }
          }
        }
      });
  }

  /**
   * Stops polling the status of the most recent Cruise Control command
   */
  cancelStatusPolling(): void {
    this.pollingSubject.next({});
  }

  /**
   * Starts a rebate refresh command
   */
  refreshRebates(): void {
    this.cruiseControlService.refreshRebates(this.accountId).subscribe({
      next: () => this.messageService.add({severity: 'success', summary: 'Success', detail: 'Started refreshing rebates.'}),
      error: () => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to refresh rebates.'})
    });
  }

}
